(function (w, d) {

  var spoiler = {};
  spoiler.init = function (cb) {
    var panel = d.getElementsByClassName('spoiler');
    if (!panel) return;
    for (var i = 0, len = panel.length; i < len; ++i) {
      if (!panel[i].id) panel[i].id = 'spoiler-' + (i + 1);
      $('#spoiler-' + (i + 1)).append('<h2 style="display:flex; padding: 0; margin-bottom: 0; margin-top: 4px"><a style="margin-left: auto" title="Copy permalink to this block to clipboard" href="' +
        location.protocol + '//' +
        location.hostname + (location.port ? ':' + location.port : '') +
        location.pathname + location.search + '#toggle-spoiler-' + (i + 1) + '" class="btn-permalink scroll-init" data-clipboard-text="#toggle-spoiler-' + (i + 1) + '"><i>#</i></a></h2>');
      toggleSpoiler(panel, i, cb);
    }
  };

  function toggleSpoiler(elem, index, cb) {
    var toggle = d.createElement('div'),
      toggleText = (elem[index].getAttribute('data-toggle-text') ||
        '&nbsp;').split(' | '),
      togglePlacement = elem[index].getAttribute('data-toggle-placement') &&
        elem[index].getAttribute('data-toggle-placement') !== 'top' ?
        elem[index].getAttribute('data-toggle-placement') :
        'top';

    var classAddSpoiler;

    if (elem[index].getAttribute('data-spoiler-state'))
      classAddSpoiler = ' spoiler-state-' +
        elem[index].getAttribute('data-spoiler-state');
    else classAddSpoiler = ' spoiler-state-collapsed';

    if (elem[index].getAttribute(
      'data-spoiler-disabled')) classAddSpoiler += ' spoiler-disabled';

    elem[index].className += classAddSpoiler;

    toggle.id = 'toggle-spoiler-' + (index + 1);
    toggleText.push(toggleText[0]);
    toggle.className = 'spoiler-toggle';
    toggle.innerHTML = toggleText[/(^| )spoiler-state-collapsed( |$)/.test(
      elem[index].className) ? 0 : 1];

    function openningSpoiler() {
      var target = this.parentNode,
        isExpanded = /(^| )spoiler-state-expanded( |$)/.test(
          target.className),
        isConnected = target.getAttribute('data-connection');
      if (/(^| )spoiler-disabled( |$)/.test(target.className)) return false;

      target.className = isExpanded ?
        target.className.replace(/(^| )spoiler-state-expanded( |$)/,
          '$1spoiler-state-collapsed$2') :
        target.className.replace(/(^| )spoiler-state-collapsed( |$)/,
          '$1spoiler-state-expanded$2');
      this.innerHTML = toggleText[isExpanded ? 0 : 1];

      if (isConnected) {
        for (var i = 0, len = elem.length; i < len; ++i) {
          var isConnectedTo = elem[i].getAttribute('data-connection'),
            toggleTextSibling = (elem[i].getAttribute('data-toggle-text') ||
              '&nbsp;').split(' | '),
            togglePlacementSibling = elem[i].getAttribute(
              'data-toggle-placement') &&
              elem[i].getAttribute('data-toggle-placement') !== 'top' ?
              elem[i].getAttribute('data-toggle-placement') :
              'top';

          if (isConnectedTo && isConnected === isConnectedTo && target.id !==
            elem[i].id) {
            elem[i].className = elem[i].className.replace(
              /(^| )spoiler-state-expanded( |$)/,
              '$1spoiler-state-collapsed$2');
            elem[i].children[togglePlacementSibling === 'top' ?
              1 :
              0].innerHTML = toggleTextSibling[0];
          }
        }
      }

      if (typeof cb === 'function') {
        cb();
      }

      return false;
    };

    toggle.onclick = openningSpoiler

    if ((window.location.href).includes("#toggle-spoiler")) {
      var url = window.location.href
      var spoilerId = url.split('#')[1]
      if (spoilerId === toggle.id) {
        setTimeout(function () {
          $(toggle).trigger('click');
        }, 200);
      }
    }

    toggle.onmousedown = false;
    elem[index].insertBefore(toggle,
      togglePlacement == 'bottom' ? null : elem[index].firstChild);
  }

  w.spoiler = spoiler;
})(window, document);
